@import '../../../assets/styles/variables';

.spinner {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  opacity: 0.75;
  z-index: 50;

  &.withNavigation {
    height: calc(100% - 50px) !important;
    top: 50px !important;
  }
}
