.input {
  font-size: var(--mantine-font-size-sm);
}

.dropdown {
  border-color: var(--mantine-color-lionstep-blue-5);
  border-radius: var(--mantine-radius-md);
}

.option {
  font-size: var(--mantine-font-size-sm);
  border-radius: 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--mantine-color-gray-1);
  }

  &[aria-selected='true'] {
    color: var(--mantine-color-lionstep-blue-5);

    svg path {
      border-color: var(--mantine-color-lionstep-blue-5);
    }
  }
}
