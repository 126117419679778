@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-SuperItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-SuperItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Super-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-BlackItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-BlackItalic-Web.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Black-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Black-Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-BoldItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-BoldItalic-Web.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Bold-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-SemiboldItalic-Web.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Semibold-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-MediumItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-MediumItalic-Web.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-RegularItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-LightItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Light-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-ExtralightItalic-Web.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Extralight-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Extralight-Web.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-ThinItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-ThinItalic-Web.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./fonts/Graphik-Thin-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Thin-Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}
