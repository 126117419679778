@import '../../assets/styles/variables';
@import '../../assets/styles/breakpoints';

.container {
  align-items: center;
  background-color: $eggshellLight;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
}
