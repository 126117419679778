.thead {
  .tr {
    border-bottom: 0;
  }

  .th {
    color: var(--mantine-color-lionstep-gray-6);
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 0;
  }
}

.tr {
  .td {
    padding: 10px;
  }
}

tbody {
  tr {
    &:hover {
      background-color: var(--mantine-color-gray-0);
      cursor: pointer;
    }
  }
}
