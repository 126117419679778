.root {
  background-color: var(--mantine-color-white);
  border: 1px solid var(--mantine-color-default-border);
  align-items: center;
  display: flex;
}

.label {
  height: unset;
}
