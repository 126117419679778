.content {
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-lg);
}

.header {
  padding-bottom: var(--mantine-spacing-xl);
}

.title {
  font-weight: 600;
  font-size: var(--mantine-font-size-lg);
}
