.list {
  margin-bottom: 30px;
  &::before {
    border-width: 0 0 1px 0;
  }
}

.tab {
  border-width: 0 0 1px 0;
}
