@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/breakpoints';

.logoWrapper {
  @media only screen and (min-width: $breakpointSm) {
    margin-right: 10px;
  }
}

.logo {
  @media only screen and (min-width: $breakpointSm) {
    margin-right: 10px;
  }
}

.title {
  display: none;

  @media only screen and (min-width: $breakpointSm) {
    display: inline-block;
  }
}

.backButton {
  align-items: center;
  display: inline-flex;
  column-gap: var(--mantine-spacing-xs);
}

.navLinks {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 30px;
}

.navLink {
  position: relative;
  height: 50px;

  a {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: var(--mantine-font-size-sm);
  }

  &:hover {
    &::after {
      position: absolute;
      content: '';
      bottom: 0px;
      width: 100%;
      height: 2px;
      background-color: var(--mantine-color-lionstep-gray-6);
    }
  }

  &.active,
  &:active {
    color: var(--mantine-color-lionstep-blue-6);

    &::after {
      position: absolute;
      content: '';
      bottom: 0px;
      width: 100%;
      height: 2px;
      background-color: var(--mantine-color-lionstep-blue-6);
    }
  }
}
