.input {
  font-size: var(--mantine-font-size-sm);
}

.dropdown {
  border-color: var(--mantine-color-lionstep-blue-5);
  border-radius: var(--mantine-radius-md);
}

.day,
.weekday,
.calendarHeaderLevel {
  font-size: var(--mantine-font-size-sm);
}

.day {
  --day-size: var(--day-size-sm) !important;
}
